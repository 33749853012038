import React, {useEffect, useState} from 'react';
import { isPropertySignature } from 'typescript';
import './App.css';
import NavBar from './Components/Navbar';
import Map from './Map/';
import {loadMapApi} from "./utils/GoogleMapsUtils";



function App() {
    const [scriptLoaded, setScriptLoaded] = useState(false);
    const [distanceInKm, setDistanceInKm] = useState<number>(-1);

    useEffect(() => {
        const googleMapScript = loadMapApi();
        googleMapScript.addEventListener('load', function () {
            setScriptLoaded(true);
        });
    }, []);

    const renderDistanceSentence = () => {
        return (
            <div className='distance-info'>
                {`Distance between selected marker and home address is ${distanceInKm}km.`}
            </div>
        );
    };

    function Mapa(){
        
    }

    const [idiom,setIdiom]= useState('Port')




       
    return (
        <div className="App p-10 cell:p-0">
        {scriptLoaded && (
            <Map
              mapType={google.maps.MapTypeId.ROADMAP}
              mapTypeControl={true}
              setDistanceInKm={setDistanceInKm}
            />
        )}
        {distanceInKm > -1 && renderDistanceSentence()}
      
    </div>
  
    
    );
}

export default App;

