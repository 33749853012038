import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import AppEng from './AppEng';
import Navbar from "./Components/Navbar";
import PrimeiraP  from './Components/PrimeiraP';
import Vantagens from './Components/Vantagens';
import Footer from './Components/Footer';
import Contacte from './Components/Contacte';
import Download from './Components/Download';
import { AppBar, Slider } from '@mui/material';
import Questoes from './Components/Questoes';
import QuestoesEng from './Components/QuestoesEng';
import Swiper from 'swiper';
import Swipp from './Components/Swipp';
import NavBar from './Components/Navbar';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import NavBarEng from './Components/NavbarEng';
import DownloadEng from './Components/DownloadEng';
import FooterEng from './Components/FooterEng';
import VantagensEng from './Components/VantanesEng';
import Login from './Components/Login';
import PrimeiraPEng from './Components/PrimeiraPEng';

import ImgTest from './Components/ImgTest';
import Nav from './Components/Nav'
import Logout from './Components/Logout';
import Fogot_pass from './Components/Fogot_pass';
import FirstSection from './Components/GoFuelCard';
import GoFuelFleet from './Components/GoFuelFleet';
import Inicio from './Components/Inicio';

import QuemSomos from './Components/QuemSomos';
import QuemSomosEng from './Components/QuemSomosEng';
import InicioEng from "./Components/InicioEng";
import GoFuelFleetEng from './Components/GoFuelFleetEng';
import SwippEng from './Components/SwippEng';
import NossaRede from './Components/NossaRede';
import Campanhas from './Components/Campanhas';
import NossaRede2 from './Components/NossaRede2';
import GoFuelCard from './Components/GoFuelCard';
import GoFuelCardEng from './Components/GoFuelCardEng';
import CampanhasEng from './Components/CampanhasEng';
import NossaRedeEng from './Components/NossaRedeEng';
import TruckStop from './Components/TruckStop';
import AppTruckStop from './AppTruckStop';
import AppTruckStopEng from './AppTruckStopEng';

import {IntlProvider} from "react-intl";
import { useIntl } from 'react-intl';

import { Domain } from 'domain';
import { domainToUnicode } from 'url';

import Portugues from "./Languages/pt-POR.json";
import English from "./Languages/en-Us.json";
import {useState} from 'react'
import MudarIdioma from './Components/MudarIdioma';
import ProviderIntl from './ProviderIntl';


const local = navigator.language;
let l=navigator.language;

const url=window.location.pathname;

let lang=English;


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(

<ProviderIntl/>

  
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

